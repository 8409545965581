












import { Component, Vue } from "vue-property-decorator";
import TopNavbar from "@/components/TopNavbar.vue";
import Footer from "@/components/Footer.vue";
import { isGuidePath } from "./data/guides";
@Component({ components: { TopNavbar, Footer } })
export default class App extends Vue {
  get columnWidthClass(): string {
    return this.hasLeftSidebar || this.$router.currentRoute.path === "/"
      ? "is-8-desktop is-10-tablet"
      : "is-half";
  }
  get hasLeftSidebar(): boolean {
    return isGuidePath(this.$route.path);
  }
}
